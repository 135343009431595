const ServiceData = [

  

    {
        id: 1,
        imgsrc: "https://yugalkunj.org/images/Ca_yugalkunjclasses.jpg",
        title: "Yugal Kunj Classes",
        Desc: "Click here to get more info Yugal Kunj Classes ",
        Link: "/CAD/AllEvent",
        RegistrationLink: "/CAD/AllEvent ",
        Readbtn: "Read More",
        registerbtn: "Upcoming-Event",
        target:"_"
        
 
    },

    {
        id: 2,
        imgsrc: "https://yugalkunj.org/images/YugalKunjexpansion.jpg",
        title: "Yugal Kunj expansion",
        Desc: "Click here to get more info Yugal Kunj expansion  ",
        Link: "/CAD/YugalKunjexpansion",
        RegistrationLink: "/CAD/YugalKunjexpansion",
        Readbtn: "Read More",
        registerbtn: "Read More",
        target:""

    },

    

    



    {
        id: 3,
        imgsrc: "https://yugalkunj.org/images/satsang.jpg",
        title: "Weekly Satsangs",
        Desc: "Click here to get more info weekly satsangs ",
        Link: "/CAD/AllEvent",
        RegistrationLink: "/CAD/AllEvent",
        Readbtn: "Read More",
        registerbtn: "Upcoming-Event",
        target:""

    },


    {
        id: 4,
        imgsrc: "https://stssevastorage.blob.core.windows.net/ykwebsite/resource/inspirational-1.jpg",
        title: "Words of Wisdom",
        Desc: "Spiritual nuggets from the Words of Wisdom that are written by Didi Ji and sent out every Friday. ...",
        Link: "/CAD/wordsofwisdom",
        RegistrationLink: "/CAD/wordsofwisdom",
        Readbtn: "Read More",
        registerbtn: "Read Words of Wisdom",
        target:""

    }









];

export default ServiceData; 