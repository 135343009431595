
const WordofWisdomeCard = () => {
  
// const qaData = [
//     { question: "What never goes out of style?", answer: "Kindness." },
//     { question: "What makes a person attractive?", answer: "Confidence and Genuineness." },
//     { question: "How can I get rid of anxiety?", answer: "Let go of expectations" },


//     { question: " How can I stop worrying?", answer: "By having faith that everything is happening for a reason." },
//     { question: "How must I serve God?", answer: "With gratitude and humility." },
//     { question: "What can I do to avoid envy?", answer: "Convert it into admiration." },
//     { question: "What is the importance of prayer?", answer: "It is food and water for the soul." },
//     { question: "What is more precious than gold?", answer: "True friendship." },
//     { question: "Who should I be cautious about?", answer: "Those who flatter you." },


//     { question: "Why am I not moving ahead in my spiritual life?", answer: "Procrastination" },
//     { question: "What will help me find time for devotional practice?", answer: "Accepting that peace and happiness are the byproducts of devotion." }
  




    


    
//   ];



    return (

        <>

            {/* <!--Sidebar Page Container--> */}
            <div className="sidebar-page-container">
                <div className="auto-container">
                    <div className="row clearfix">

                        {/* <!--Content Side / Blog Detail--> */}
                        <div className="content-side col-lg-12 col-md-12 col-sm-12">
                            <div className="post-details">
                                {/* <!--Blog Details--> */}
                                <div className="blog-details">


                                    <div className="lower-box">


                                        <div className="panel panel-primary">
                                            <div className="panel panel-heading">
                                                {/* <h4>Words of Wisdom - Question & Answer Session - #140   </h4> */}
                                                <h4>Words of Wisdom - Tragedies teach us </h4>

                                            </div>

                                            <div className="panel panel-body">
                                                <div className='row'>
                                                    <div className="col-md-12">

                                                        {/* <p> Children are fearless; they are always trying something new. Adults would do well to get into the spirit of a child and do something new. I have a few ideas. I dare you to try them! </p> */}
                                                        {/* <ul className="wordofwisdon_list">
                                                            <li> For the one who has never done it……. I dare you to walk in the rain without an umbrella.
                                                            </li>

                                                            <li> For the stoic male………I dare you to express your emotions and know that it’s okay to cry. </li>

                                                            <li>  For the female……..I dare you to contain your emotions at times and not allow your heart to rule your mind.</li>

                                                            <li> For the person who doesn’t cook…….I dare you to prepare a meal.</li>

                                                            <li> For the worrywart……..I dare you to stop worrying and start living.</li>

                                                            <li>  For the married person……….I dare you to look at your spouse’s point of view. </li>

                                                            <li>   For the highly organized person…..I dare you to do something spontaneous.</li>
                                                            <li> For the procrastinator…….I dare you to finish an important task today. </li>
                                                            <li> For the angry person………I dare you to decide that anger is a harmful emotion.</li>
                                                            <li> For the critical person……..I dare you replace your critical behavior with an understanding heart. </li>
                                                            <li> For the lazy person……..I dare you to discover the joy of working hard.</li>

                                                            <li> For the workaholic……..I dare you to relax and sit a while without doing anything.</li>
                                                            <li> For the teenager……….I dare you to communicate with your family.</li>
                                                            <li> For the seniors……..I dare you to ignore your age and think young.</li>

                                                            <li> For the juniors………I dare you to learn from the seniors in your family.</li>
                                                            <li> For the social media addict………I dare you to live one entire weekend without social media.</li>
                                                            <li> For the video game addict……..I dare you to play outside and discover the joys of Nature.</li>
                                                            <li> For everyone reading this………I dare you to have a wonderful weekend. </li>
                                                      
                                                      
                                                      
                                                      
                                                        </ul> */}
                                                      

                                                      <p>The horrific events that unfolded on September 11, 2001 sent shock waves throughout the U.S. 23 years later, we can still vividly remember where we were on that morning and recall the whole gamut of emotions we felt in the aftermath. Our world was never going to be the same again. A dark cloud hovered for a long time over our collective consciousness and individual minds.
                                                    </p>

                                                    <p> At the same time, there is always a silver lining behind every dark cloud. Every tragedy forces us to accept truths that are otherwise too uncomfortable for us to reflect on.  Every tragedy leaves us with lessons that we have no choice but to accept, simply because they stare us glaringly in the face. We saw on that sad day that life is unpredictable, and death is inevitable. When those who perished on that day left home in the morning they did not think that this was going to be their final day on earth. None of us is privy to that information.
                                                    </p>

                                                    <p> Tragedies also force us to accept that life doesn’t always remain the same. Life is not a stagnant pond. Life is an ever-flowing river. Things change; people change; situations change. The only constant in life is ‘change’.
                                                     </p>

                                                     <p> When faced with excruciatingly difficult situations in life, we learn that we humans are resilient creatures. When we are in the depths of despair, we have no way to go except up. We cry; we grieve; we become despondent, but then we rise up. We rebuild ourselves and continue to live, hopefully wiser, due to the lessons tragedy has taught us.
                                                    </p>

                                                    {/* <p> The caterpillar must shed its former self for the butterfly to arrive. Every ending leads to a new beginning.</p> */}

                                                    {/* <div>
      {qaData.map((item, index) => (
        <div key={index} >
          <h5> Q: {item.question}</h5>
          <p style={{fontSize: '20px', borderBottom:"1px solid #ccc" }}> A: {item.answer}</p>
        </div>
      ))}
    </div> */}
                                                     

                                                        <hr />
                                                        <p> Yours,</p>
                                                        <p> Didi Ji</p>
                                                        <p className="text-center"> Note: You may direct your spiritual questions to me
                                                            directly at</p>
                                                        <p className="text-center"> s_didi@radhamadhavsociety.org</p>


                                                    </div>

                                                </div>

                                            </div>


                                        </div>




                                    </div>






                                </div>





                            </div>

                        </div>


                    </div>
                </div>
            </div>
            {/* <!-- End Sidebar Page Container --> */}



        </>


    )

}

export default WordofWisdomeCard;